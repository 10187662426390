import React, { useState } from 'react';
import '../styles/Login.css';

function Login() {
  const [formData, setFormData] = useState({ email: '', password: '' });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:5000/api/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (response.ok) {
        alert('Login successful!');
      } else {
        alert(`Error: ${result.message}`);
      }
    } catch (error) {
      alert('Failed to log in. Please try again.');
    }
  };

  return (
    <div className="login-page">
      <header className="main-header">
        <div className="small-logo"><a href="/">Axii</a></div>
        <nav>
          <ul className="nav-links">
            <li><a href="/about">About</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </nav>
      </header>

      <div className="hero-section">

      </div>

      <div className="login-content">
        <h1>Axii Login</h1>
        <form className="login-form" onSubmit={handleSubmit}>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />

          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            placeholder="Your Password"
            value={formData.password}
            onChange={handleInputChange}
            required
          />

          <button type="submit" className="cta-button">Login</button>
        </form>

        <div className="forgot-password">
          <a href="/reset-password">Forgot your password?</a>
        </div>

        <div className="disclaimer">
          By logging in, you agree to our Terms of Service and Privacy Policy. Unauthorized use is prohibited.
        </div>

      </div>

      <footer className="main-footer">
        <p className="copyright">&copy; 2024 Axii Transportation, LLC. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Login;
