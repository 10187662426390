import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';

function Header() {
  return (
    <header className="main-header">
      <div className="small-logo"><a href="/">Axii</a></div>
      <nav>
        <ul className="nav-links">
          <li><a href="/about">About</a></li>
          <li><a href="/contact">Contact</a></li>
          <li className="login-button"><a href="/login">Login</a></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
