import React from 'react';
import '../styles/About.css';

function About() {
  return (
    <div className="about-page">
      <header className="main-header">
        <div className="small-logo"><a href="/">Axii</a></div>
        <nav>
          <ul className="nav-links">
            <li><a href="/about">About</a></li>
            <li><a href="/contact">Contact</a></li>
            <li className="login-button"><a href="/login">Login</a></li>
          </ul>
        </nav>
      </header>

      <div className="hero-section">
      </div>

      <div className="about-content">
      <div class="promo-header">What is Axii Transportation?</div>
  <div class="promo-content">
      <div class="promo-subheader"><i>The future of personalized travel!</i></div>
      <p>Are you tired of one-size-fits-all transportation solutions that don't cater to your unique needs? Say goodbye to inflexible schedules and hello to <strong>unmatched flexibility, safety, and convenience</strong> with Axii Transportation.</p>
      <p>At Axii, <strong>we're on a mission to redefine how people move</strong>. We believe that travel should be more than just getting from point A to point B—it should be an experience tailored just for you. Whether you're a driver seeking greater autonomy and better earnings, or a rider craving comfort and reliability, <strong>Axii empowers you to take control</strong>.</p>
      <div class="promo-subheader">Why Choose Axii Transportation?</div>
      <ul class="promo-list">
          <li><strong>Customized Experience:</strong> Tailor your journey to suit your preferences. Need a quiet ride to prepare for a big meeting? Prefer a specific route? With Axii, you're in charge.</li>
          <li><strong>Safety First:</strong> Your well-being is our top priority. We employ the latest safety protocols and technologies to ensure a secure journey every time.</li>
          <li><strong>Ultimate Convenience:</strong> Book rides or set your driving schedule with just a few taps. Our user-friendly app makes it effortless.</li>
      </ul>
      <p>Don't settle for ordinary when you can have extraordinary. <strong>Experience the freedom and personalization that only Axii Transportation can offer.</strong></p>
  </div>
      </div>

      <footer className="main-footer">
        <div className="social-media">
          <a href="#"><i className="fab fa-facebook"></i></a>
          <a href="#"><i className="fab fa-twitter"></i></a>
          <a href="#"><i className="fab fa-linkedin"></i></a>
          <a href="#"><i className="fab fa-google-plus"></i></a>
        </div>
          <p className="copyright">&copy; 2024 Axii Transportation, LLC. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default About;
