import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import '../styles/Contact.css';

function Contact() {
  const [captchaValid, setCaptchaValid] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });

  // Handle form data changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle reCAPTCHA validation
  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaValid(true);
    } else {
      setCaptchaValid(false);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaValid) {
      alert("Please verify that you're not a robot.");
      return;
    }

    try {
      const response = await fetch('http://localhost:5000/api/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...formData, token: captchaValid }),
      });

      const result = await response.json();
      if (response.ok) {
        alert('Form submitted successfully!');
        setFormData({ name: '', email: '', message: '' });
      } else {
        alert(`Error: ${result.message}`);
      }
    } catch (error) {
      alert('Failed to submit the form. Please try again later.');
    }
  };

  return (
    <div className="contact-page">
      <header className="main-header">
        <div className="small-logo"><a href="/">Axii</a></div>
        <nav>
          <ul className="nav-links">
            <li><a href="/about">About</a></li>
            <li><a href="/contact">Contact</a></li>
            <li className="login-button"><a href="/login">Login</a></li>
          </ul>
        </nav>
      </header>

      <div className="hero-section">
      </div>

      <div className="contact-content">
        <div className="contact-info">
          <h1>Get in Touch..</h1>
          <p className="contact-text">Have a question or a partnership in mind? <br /> Fill out the form below, and someone <br /> will get back to you as soon as possible!</p>
          <p className="contact-response">Typically Responds time: <b>24 Hours</b></p>
          <div className="contact-details">
            <p><strong>Email:</strong> feedback@axiiapp.com</p>
            <p><strong>Phone:</strong> +1 ‪(317) 620-0018‬</p>
            <p><strong>Based out of Indianapolis</strong></p>
          </div>
          <div className="social-media">
            <a href="#"><i className="fab fa-facebook"></i></a>
            <a href="#"><i className="fab fa-twitter"></i></a>
            <a href="#"><i className="fab fa-linkedin"></i></a>
            <a href="#"><i className="fab fa-google-plus"></i></a>
          </div>
          <p className="contact-serving"><strong>Serving clients worldwide.</strong></p>
        </div>

        <form className="contact-form" onSubmit={handleSubmit}>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />

          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />

          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            placeholder="Your Message"
            rows="5"
            value={formData.message}
            onChange={handleInputChange}
            required
          ></textarea>

          <ReCAPTCHA className="recaptcha"
            sitekey="6LerO4MqAAAAAH7EyOqdF0gr-KxGnuPRLZGYAA69"
            onChange={handleCaptchaChange}
          />

          <button type="submit" className="cta-button">Submit</button>
          <p className="submit-text"><br /> Your information is confidential and will not be shared</p>
        </form>
      </div>

      <footer className="main-footer">
        <p className="copyright">&copy; 2024 Axii Transportation, LLC. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Contact;
