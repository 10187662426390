import React, { useState, useEffect } from 'react';
import Header from './Header';
import '../styles/Home.css';

function Home() {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [isCountdownFinished, setIsCountdownFinished] = useState(false);

  // Get the target date (use environment variable or fallback to a default)
  const targetDate = new Date(process.env.REACT_APP_LAUNCH_DATE || '2025-12-31T00:00:00'); // Default: Dec 31, 2024

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference <= 0) {
        clearInterval(timer); // Stop the timer if the target date is reached
        setIsCountdownFinished(true); // Mark countdown as finished
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        setTimeLeft({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(timer); // Clean up the interval on component unmount
  }, [targetDate]);

  return (
    <div className="landing-page">
      <Header />
      <div className="content-container">
        <header className="landing-header">
          <h1>We Are Doing Great, <br /> Almost THERE...</h1>
          <div className="buttons">
            <a href="/contact" className="cta-button">Get in Touch</a>
          </div>
        </header>

        {isCountdownFinished ? (
          <section className="countdown-end">
            <h2>We Are Live!</h2>
            <p>Welcome to the new era of Axii Transportation. Thank you for your patience!</p>
          </section>
        ) : (
          <section className="countdown">
            <div className="countdown-item">
              <span>{timeLeft.days}</span>
              <p>Days</p>
            </div>
            <div className="countdown-item">
              <span>{timeLeft.hours}</span>
              <p>Hours</p>
            </div>
            <div className="countdown-item">
              <span>{timeLeft.minutes}</span>
              <p>Minutes</p>
            </div>
            <div className="countdown-item">
              <span>{timeLeft.seconds}</span>
              <p>Seconds</p>
            </div>
          </section>
        )}
      </div>

      <footer className="landing-footer">
        <div className="social-media">
          <a href="#"><i className="fab fa-facebook"></i></a>
          <a href="#"><i className="fab fa-twitter"></i></a>
          <a href="#"><i className="fab fa-linkedin"></i></a>
          <a href="#"><i className="fab fa-google-plus"></i></a>
        </div>
      </footer>
      <footer className="main-footer">
        <p className="copyright">&copy; 2024 Axii Transportation. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Home;
