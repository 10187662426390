import React, { useState } from 'react';
import '../styles/ResetPassword.css';

function ResetPassword() {
  const [formData, setFormData] = useState({ email: '' });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:5000/api/auth/reset-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (response.ok) {
        alert('Password reset link sent to your email!');
      } else {
        alert(`Error: ${result.message}`);
      }
    } catch (error) {
      alert('Failed to send reset password email. Please try again.');
    }
  };

  return (
    <div className="reset-password-page">
      <header className="main-header">
        <div className="small-logo"><a href="/">Axii</a></div>
        <nav>
          <ul className="nav-links">
            <li><a href="/about">About</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </nav>
      </header>

      <div className="hero-section">
      </div>
      <div className="reset-password-content">
        <h2><a href="/login"><i className="fa-solid fa-arrow-rotate-left"></i> Return back to login</a></h2>
        <h1>Forgot Your Password?</h1>
        <p>No problem! Enter your email address, and we’ll send you a link to reset your password. If you don’t see it shortly, check your spam folder or try again.</p>
        <form className="reset-password-form" onSubmit={handleSubmit}>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />

          <button type="submit" className="cta-button">Send Reset Link</button>
        </form>
      </div>

      <footer className="main-footer">
        <p className="copyright">&copy; 2024 Axii Transportation, LLC. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default ResetPassword;
